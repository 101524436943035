

























import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { ViewDataScope } from '@/modules/shared/entities/ViewData'

import ViewDataMixin from '@/modules/shared/mixins/ViewDataMixin'

import Headline from '@/modules/shared/components/Headline.vue'
import ContributorList from '../components/ContributorList.vue'
import SupportList from '../components/SupportList.vue'

const ContributorModule = namespace('contributor')

@Component({
  components: {
    Headline,
    ContributorList,
    SupportList,
  },
})
export default class Contributors extends Mixins(ViewDataMixin) {
  viewDataScope = ViewDataScope.contributor

  @ContributorModule.Action
  private readonly fetchContributors!: () => Promise<void>

  @ContributorModule.Action
  private readonly fetchContributorImages!: () => Promise<void>

  mounted() {
    this.fetchContributors()
    this.fetchContributorImages()
  }
}
