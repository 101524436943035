













import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Image from '@/modules/shared/entities/Image'

const ContributorModule = namespace('contributor')

@Component
export default class SupportList extends Vue {
  @ContributorModule.Getter
  private readonly contributorImages!: Image[]
}
