













import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Contributor from '@/modules/shared/entities/Contributor'

const ContributorModule = namespace('contributor')

@Component
export default class ContributorList extends Vue {
  @ContributorModule.Getter
  private readonly contributors!: Contributor[]
}
